import React from "react"
import {
  Grid,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@material-ui/core"
//COMPONENTS
import BulletItem from "../components/bullet-item"
import Article from "../components/article"
import Carousel from "../components/carousel"
import NumericList from "../components/numeric-list"
import Section from "../components/section"
import HeroSection from "../components/hero-section"
import Testimonial from "../components/testimonial"
import ListCard from "../components/list-card"
import AboutImage from "./assets/Ivone-Foto-4.jpg"
import About from "../components/about"
import Card from "../components/card"
import CtaButton from "../components/cta-button"
import Navigation from "../components/navigation"
import Footer from "../components/footer"

//IMAGES
import HeroImage from "./assets/CONFIDENCE-pexels-moose-photos-1036622.jpg"
import IvoneImage from "./assets/pexels-andre-furtado-5180962.jpg"
import ImageEstrategia from "./assets/pexels-pixabay-277124-2.jpg"
import ImageSensibilidad from "./assets/pexels-nataliya-vaitkevich-5201527-2.jpg"
import ImageAccion from "./assets/pexels-andre-furtado-5180962.jpg"
import ImageEquipo from "./assets/pexels-andrea-piacquadio-3830752.jpg"
import ImageAreas from "./assets/pexels-asad-photo-maldives-457881.jpg"
import ImageTurkisWay from "./assets/pawel-czerwinski--vcE6c-UP9E-unsplash.jpg"
import ImagePersonalmente from "./assets/PERSONALMENTE-pexels-karolina-grabowska-4467683-2.jpg"
import ImageProfesionalmente from "./assets/PROFESIONALMENTE-pexels-anna-shvets-3727457.jpg"
import ImageEmocionalmente from "./assets/EMOCIONALMENTE-pexels-andre-furtado-1417255.jpg"
import ImageFisicamente from "./assets/FISICAMENTE-pexels-andrea-piacquadio-3757375.jpg"

//ICONS
import { RiMoneyEuroCircleLine } from "react-icons/ri"
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io"
import { ImCircleRight } from "react-icons/im"
import { BsCheckCircle } from "react-icons/Bs"
import { ImQuotesRight } from "react-icons/im"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { BsCalendar } from "react-icons/bs"
import Checkout from "../components/checkout"

export default function CATC() {
  const [discount, setDiscount] = React.useState(1)
  const [showCheckout, setShowCheckout] = React.useState(false)
  const basePrice = 97
  const price = Number(basePrice * discount).toFixed(2)

  const iconQuotes = <ImQuotesRight color="#269fb2" size="40px" />
  const iconCalendar = <BsCalendar color="#269fb2" size="40px" />
  // const iconQuotes2 = <RiDoubleQuotesR color="#269fb2" size="20px" />
  const iconEuro = <RiMoneyEuroCircleLine color="#269fb2" size="40px" />
  const bulletIcon1 = <IoIosArrowForward color="#269fb2" size="36px" />
  //const bulletIcon2 = <IoIosArrowForward size="20px" />
  // const bulletIcon2 = <BsCheckCircle color="#269fb2" size="22px" />
  const bulletIcon2 = <BsCheckCircle color="#21447d" size="26px" />
  //const bulletIcon3 = <BsCheckCircle color="#269fb2" size="26px" />
  const bulletIcon3 = <BsCheckCircle color="#269fb2" size="26px" />
  const bulletIcon4 = <ImCircleRight color="#269fb2" size="26px" />
  const bulletIconSI = <BsCheckCircle color="#269fb2" size="27px" />
  const bulletIconNO = <AiOutlineCloseCircle color="#269fb2" size="27px" />
  const iconPreguntas = <IoIosArrowDown color="#269fb2" size="27px" />

  const numericListText = [
    <span>
      {" "}
      Iremos <strong> directo a la acción.</strong> Durante el curso{" "}
      <strong> aplicarás paso a paso lo aprendido, </strong> por lo que{" "}
      <strong> notaras rápidamente resultados</strong> y además, estaré allí
      durante todo el curso para resolver tus dudas y apoyarte en todo momento.
    </span>,
    <span>
      Es super <strong>exclusivo</strong>, el número de participantes es muy
      reducido, lo que facilita un{" "}
      <strong>ambiente de confianza en el que te sentirás segura </strong> y
      además contarás con el{" "}
      <strong> apoyo y la atención que necesitas </strong> para superar tus
      retos.
    </span>,
    <span>
      <strong>Aprenderás herramientas y estrategias</strong> específicas para
      potenciar tu confianza{" "}
      <strong> que te serán de utilidad para toda la vida.</strong>
    </span>,
    <span>
      Nos enfocaremos en{" "}
      <strong>
        desarrollar la nueva mentalidad de la mujer segura y confiada que vive
        dentro de ti
      </strong>
      , lo que{" "}
      <strong>
        impactará todo lo que hagas en tu vida de ahora en adelante
      </strong>
      .
    </span>,
  ]

  const Title_SI = ["Es para ti sí."]
  const List_SI = [
    "Estas cansada de sentir que tus decisiones y emociones dependen de lo que digan o piensen los demás.",
    "Estás decidida a tomar acción y empezar a utilizar tus talentos, capacidades y experiencia para alcanzar tus propias metas y en favor de lo que solo tú de verdad quieres.",
    "Estas abierta a nuevas ideas y a ponerlas en práctica para experimentar la diferencia.",
    "Necesitas motivación adicional e instrucciones claras y concretas para ir integrando lo aprendido en tu vida paso a paso.",
    "Piensas que la mejor inversión que puedes hacer es en ti misma.",
  ]
  const Title_NO = ["NO es para ti sí."]
  const List_NO = [
    "Prefieres quedarte en tu zona de confort, aunque te sientas insatisfecha y sigas desperdicianto tus talentos y tu inteligencia.",
    "No crees que mejorar tu confianza y cambiar tu mentalidad pueda realmente transformar tu situación.",
    "Quieres seguir haciendo las cosas a tu manera y no estas lista para integrar nuevos hábitos y herramientas en tu vida.",
    "Te gustaría transformar tu situación pero no estas dispuesta a tomar acción.",
    "Te sientes super feliz y contenta con la vida que llevas en todos los aspectos.",
  ]
  const List_Incluye = [
    <span>
      {" "}
      <strong> DOS sesiones de coaching individuales </strong> de{" "}
      <strong>30 min</strong> cada una en las que hablaremos de{" "}
      <strong>
        tus desafíos personales y nos enfocaremos específicamente en ellos
      </strong>{" "}
      durante el curso.{" "}
    </span>,
    <span>
      {" "}
      <strong>4 masterclass </strong> grupales virtuales en directo de{" "}
      <strong>90 </strong>min en las que además haremos{" "}
      <strong>ejercicios prácticos</strong>, habrá tiempo para{" "}
      <strong>resolver tus dudas</strong> y nos concentraremos en superar tu
      desafío del momento.
    </span>,
    <span>
      {" "}
      <strong>1 workbook semanal </strong> con ejercicios de introspección y
      reflexión super potentes que te ayudaran a{" "}
      <strong>avanzar más rápidamente.</strong>
    </span>,
    <span>
      {" "}
      <strong>Grupo de Telegram </strong> en dónde te compartiré material de
      apoyo y donde podrás{" "}
      <strong>
        compartir tus experiencias, desafíos y te sentirás acompañada durante
        todo el curso
      </strong>
      .
    </span>,
  ]
  const Testimonio_1 = `Llevaba un largo tiempo mal conmigo mismo, no me aguantaba ni yo mismo. Vivía con resignación, frustración e insatisfecho totalmente con la vida que llevaba. 
  Me sentía perdido. Gracias al coaching con Ivone tengo más confianza en mí mismo, más fuerza y finalmente estoy tomando acción.
  He mejorado mis relaciones sociales y de pareja al entender que yo soy el responsable de todo y que tengo que tomar la responsabilidad y las riendas de mi vida en mis manos.
  Este coaching te ayuda a reconectar contigo mismo, a dar un paso hacia el interior. Creo que es el comienzo de un viaje apasionante que nunca acaba. ¡Muchas gracias por todo Ivone!`
  const TestName_1 = "Marcello Ortíz"

  const Testimonio_2 = `El curso Amplifica tu Confianza me ha cambiado la vida por completo.
  Este curso te ayuda a descubrir los pensamientos que te estan saboteando.
  Con este curso logré dejar el trabajo que tenía, aunque era bastante estable, porque no me sentía identificada con él. Buscaba algo 
  con que ayudar a los demás y sentirme mucho más realizada y este curso me dio la confianza que necesitaba para atreverme.
  Al principio tenía mucho miedo, con el curso identifiqué mis fortalezas, mis valores y mis pasiones y fui capaz de enfrentar el miedo. 
  Finalmente tengo un nuevo trabajo donde puedo utilizar mis talentos y toda mi experiencia y me siento super entusiasmada con este nuevo comienzo. GRACIAS Ivone.`
  const TestName_2 = "Alma María Gómez."

  const Testimonio_3 = `Mi recomendación total al curso Amplifica tu Confianza. Todo el tiempo me sentí 
  comprendida y acompañada en este proceso y además siempre me sentí con la libertad de expresar 
  todo lo que pensaba y de no ser juzgada, yo creo que esto me ayudó muchísimo a mejorar mi confianza.
  Además los ejercicios semanales y el acompañamiento por Telegram, me ayudaron mucho para trabajar mis bloqueos en el momento 
  en que surgían. De verdad Ivone muchísimas gracias! 
  Es gracias a todo lo que aprendí contigo que ahora me siento mucho más segura de mí misma y de lo que puedo lograr. 
  Recomiendo a Ivone 100%.`
  const TestName_3 = "Vero Pimentel"

  const Testimonio_4 = `Amplifica tu Confianza me permitió reconectar con la mujer segura que fui cuando era más jóven. Me hizo recordar cosas que eran importantes en mi vida. 
  Después de años de batallar finalmente logré transformar muchas ideas que me estaban limitando y que me tenían frustrada y super bloqueada. También he notado como mis relaciones 
  interpersonales han mejorado muchísimo, todos confían más en mí, las oportunidades se estan abriendo y ya no las dejo pasar. Me siendo mucho más feliz de ser yo misma.`
  const TestName_4 = "Mary Roldán."

  const Testimonio_5 = `El curso Amplifica tu Confianza con Ivone cambió totalmente la forma de ver mi propia vida. 
  Cuando mi confianza mejoró, me alejé de relaciones conflictivas, me cambié de trabajo, 
  dejé de hacer cosas que me hacían daño porque ya no tuvo más sentido para mi y hasta mis amigas notaron que cambié.  
  Ahora me siento capaz de lograr muchas cosas que antes veía imposibles. ¡Gracias por tanto Ivone!`
  const TestName_5 = "Sandra Flores."

  const Testimonio_6 = `Amplifica tu confianza marcó un antes y un después en mi vida. Estoy totalmente agradecida a la amiga que me lo recomendó y a 
  Ivone por ser tan generosa y compartir todos sus conocimientos y experiencias conmigo. He hecho muchos cambios en mi vida, ahora 
  me siento mucho más ligera para ser quien soy, y por fin sé lo que es confiar en una misma y también estoy segura de que puedo lograr lo que yo quiera.`
  const TestName_6 = "Gaby Rubalcaba."

  const Testimonio_7 = `En Amplifica tu Confianza aprendí muchas herramientas para aplicar en mi vida diaria que
  me ayudan a sentirme con más confianza en mi misma y en lo que puedo hacer. 
  Viendo todos los beneficios que ha traído a mi vida, como se ha transformado y
  como me siento hoy en comparación de cuando empecé, creo que es la mejor inversión que he hecho en mi misma en siglos!
  Mi mejor recomendación es que si quieres invertir en ti misma, hagas este curso y experimentes tu misma la diferencia.
  Muy agradecida contigo Ivone, Saludos.`
  const TestName_7 = "Carla Fuentes."

  const Testimonio_8 = `Al grano: te recomiendo a Ivone totalmente.
  Si hubiera encontrado este curso antes me hubiera ahorrado mucha frustración
  y mucho sufrimiento y hubiera logrado, todo lo que he logrado desde que empece este curso,
  hace mucho tiempo. Lo que más me gusto es el enfoque de estrategia y acción, porque pude ver la diferencia en mi vida
  inmediatamente. No lo pienses más, apúntate! Saludos Ivone!`
  const TestName_8 = "Laura Adán"

  const Testimonio_9 = `¡Transformación total! Este curso marcó un antes y un después en mi vida!. 
  ¡Ojalá nos enseñaran todo esto en las escuelas!
    Hoy me siento confiada de mi misma como nunca antes lo había sentido. Hoy sé que puedo lograr todo lo que me proponga.`
  const TestName_9 = "Eva López"

  const Testimonio_10 = `Gracias a Amplifica tu Confianza, logré renunciar al trabajo con el que ya tenía años insatisfecha. 
    En mi nuevo trabajo en verdad siento que estoy aprovechando toda mi experiencia y me siento mucho más felíz. 
    Además ¡Todas mis amigas notaron el cambiaso que dí!.  Ahora me siento empoderada, capaz de lograr muchas cosas que 
    antes veía imposibles. ¡Gracias Ivone!`
  const TestName_10 = "Sandra Flores."

  return (
    <>
      <Navigation active={3} />
      <HeroSection
        id="Start Image"
        bgImage={HeroImage}
        overlay="full"
        overlayColor="rgba(68, 68,68, .2)"
        className="tenor"
        h1={
          <span>
            <br />
            <br />
            <br />
            Amplifica tu Confianza <br /> y <br />
            ¡Consigue lo que quieres!
          </span>
        }
        titleClassName="white"
      ></HeroSection>

      <Section color="turkis-blue1">
        <Article className="center tenor white font-size-verylarge lineheight-verylarge ">
          Apuesto a que no te has dado cuenta del
          <br />
          gran impacto que la falta de confianza en ti misma
          <br />
          puede estar jugando en tu vida…
        </Article>
      </Section>

      <Section color="white">
        <Article
          layout="wide"
          titleClassName="bold blue1 font-size-verylarge"
          title="¿Te identificas con algunas de estas ideas?"
        >
          <Grid container spacing={1}>
            <Grid item md={6}>
              <BulletItem icon={bulletIcon1}>
                Sientes que{" "}
                <strong>
                  lo que haces actualmente no te permite aprovechar y
                  desarrollar tus habilidades y la experiencia
                </strong>{" "}
                que has acumulado en tu vida.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Sabes que <strong>puedes más</strong>, porque ya lo has
                demostrado antes, pero en este momento{" "}
                <strong>te sientes atorada</strong>, te falta claridad y{" "}
                <strong>no sabes qué te esta deteniendo</strong>.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Quizás ya has leído varios libros de desarrollo personal, has
                hecho visualizaciones y meditaciones y, sin embargo,{" "}
                <strong>
                  {" "}
                  todo eso que has aprendido no logras aplicarlo del todo a tu
                  vida.
                </strong>
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Te sientes{" "}
                <strong> frustrada e insatisfecha con la vida</strong> que
                llevas actualmente,{" "}
                <strong>
                  no la disfrutas en plenitud porque no estas haciendo lo que te
                  gustaría
                </strong>
                .
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Quizá tienes un <strong>trabajo </strong>que, aunque estable, ni
                te gusta{" "}
                <strong>ni te llena personal ni profesionalmente</strong> y que
                además, te deja super agotada.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Tal vez{" "}
                <strong> quieres empezar con tu propio negocio, </strong> pero
                tienes
                <strong> muchas dudas.</strong>
              </BulletItem>
            </Grid>
            <Grid item md={6}>
              <BulletItem icon={bulletIcon1}>
                Quisieras{" "}
                <strong>encontrar algo que te dé más satisfacción</strong>.
                Tienes ideas de lo que te gustaría hacer,{" "}
                <strong>pero no te atreves a empezar.</strong>
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                “¿Qué tal si no eres lo suficientemente buena?” ¿Qué tal si
                “todavía no estás lista”, o todavía “no sabes lo suficiente”?
                ¿Cómo saber si “estás lo suficientemente preparada” o todavía
                no? El <strong> perfeccionismo </strong> te tiene paralizada.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Te sientes insegura y dudas de ti misma o de lo que podrías
                lograr. <strong> Te falta motivación.</strong>
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Has estado largo tiempo en esta situación y, aunque por momentos
                logras olvidarla, otras veces se siente más fuerte, incluso como
                <strong> ansiedad</strong> y otras como <strong> miedo.</strong>
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                <strong>Dependes económicamente</strong> de tu pareja o te
                cuesta llegar a fin de mes con tantos gastos y eso{" "}
                <strong>afecta fuertemente tu autoestima.</strong>
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Sientes dentro de ti esa{" "}
                <strong>
                  necesidad urgente de hacer algo para transformar esa situación
                </strong>
                , y que el dinero empiece a llegar mas fácilmente.
              </BulletItem>
            </Grid>
          </Grid>
          <br />
          <br />
          <div className="center bold turkis-blue1  font-size-medium lineheight-verylarge ">
            Si te identificas con varias de las ideas anteriores lo más seguro
            es que
            <br />
            tu falta de confianza tenga cartas en el asunto.
            <br />
          </div>
        </Article>
      </Section>

      <Section color="blue1" small>
        <Article>
          <div className="center bold playfair white font-size-large lineheight-verylarge ">
            Y por si fuera poco, la pandemia te hizo cuestionarte muchas cosas y
            <br />
            por eso llegaste a la conclusión de que...
          </div>
          <br />
          <div className="narrow white  tenor bold center font-size-verylarge  lineheight-verylarge">
            ¡Éste es tu momento!
          </div>
        </Article>
      </Section>

      {/* <Section small>
        <Article className="narrow  playfair bold center font-size-verylarge  lineheight-verylarge">
          ¡Éste es tu momento!
        </Article>
      </Section> */}

      <Section color="white">
        <Article layout="narrow">
          <div className="center bold turkis-blue1 playfair font-size-verylarge lineheight-large">
            ¿Sabías que la falta de confianza
            <br />
            impide que conectes con tus más grandes sueños y
            <br /> te mantiene alejada de ellos?
          </div>
          <br />
          <div className="center  font-size-medium lineheight-large">
            La falta de confianza le quita emoción a tu vida, y también se esta
            robando tu motivación y tu ilusión.
          </div>
          <br />
          <div className="center blue1 bold font-size-large lineheight-large">
            Cuando te falta confianza, todo se ve muy difícil de lograr.
          </div>
          <br />
          <div className="center font-size-medium lineheight-large">
            Cuando no confías en ti misma, tu inseguridad se nota, todo el mundo
            lo percibe, nadie confía en ti:
            <br />
            no consigues empleo, tus relaciones sufren, los clientes no llegan,
            obtienes pocas ventas, no obtienes ese mejor puesto que has estado
            esperando y ese aumento salarial nunca llega.
          </div>
          <div className="center font-size-medium lineheight-large"></div>
        </Article>
      </Section>

      <Section color="turkis-blue1">
        <Article>
          <div className="center white playfair  font-size-verylarge  lineheight-large">
            ¿Quisieras estar segura de qué es lo que realmente quieres en la
            vida y quitarte de encima todas esas dudas que te tienen atascada?
          </div>
        </Article>
      </Section>

      <Section color="white">
        <Article
          layout="narrow"
          className="center font-size-medium  lineheight-verylarge"
        >
          <div className="center playfair turkis-blue1 bold font-size-large lineheight-large">
            ¿Sientes fuertemente la necesidad de
            <br />
            contribuir al mundo con tus talentos, con tus habilidades, con tu
            inteligencia y con tu experiencia?
          </div>
          <br />
          <div className="center  bold font-size-medium lineheight-large">
            ¿Te imaginas disfrutar de lo que haces
            <br /> y que además eso te genere los ingresos que te mereces?
          </div>
          <br />
          <div className="center playfair blue1 bold font-size-large lineheight-large">
            ¿Te gustaría conectar con la mujer segura que vive dentro de ti y
            darle rienda suelta a todo tu potencial y emplearlo para conseguir
            eso que sueñas?
          </div>
        </Article>
      </Section>

      <Section color="blue1" small>
        <Article>
          <div className="center bold playfair white font-size-large lineheight-verylarge ">
            entonces…
          </div>
        </Article>
      </Section>

      <Section color="white">
        <Article className="narrow playfair bold center font-size-verylarge turkis-blue1 lineheight-verylarge">
          ¡Estas en el lugar correcto!
        </Article>
        <Article>
          <br />
          <div className="center blue1 bold font-size-medium lineheight-verylarge">
            Déjame decirte algo:
          </div>
          <div className="center bold font-size-medium lineheight-verylarge">
            Si eres capaz de imaginar esa vida ideal para ti,
            <br />
            es porque también eres capaz de volverla realidad.
          </div>
        </Article>
      </Section>

      <Section
        //overlayColor="rgba(98, 128, 174,.2)"
        color="blue"
        //bgImage={ImageAreas}
        overlay="full"
        overlayColor="rgba(250, 246, 246, .2)"
      >
        <Article
          layout="wide"
          titleClassName="white tenor  center font-size-verylarge  lineheight-verylarge"
          title="Tú también mereces conectar la mujer segura y confianda que vive dentro de ti y conseguir todo eso que quieres"
        ></Article>
        <Article className="blue1">
          <Grid container spacing={3} justify="center">
            <Grid item md={3} sm={6} xs={10}>
              <Card
                avatar={ImageProfesionalmente}
                titleClassName="tenor  turkis-blue1 bold center font-size-medium"
                title="Profesionalmente"
                className="blue1"
              >
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Obtener un mejor puesto.{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Reinvéntarte profesionalmente.
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Incrementar tus ingresos.{" "}
                </BulletItem>
              </Card>
            </Grid>
            <Grid item md={3} sm={6} xs={10}>
              <Card
                avatar={ImagePersonalmente}
                titleClassName="tenor bold turkis-blue1 center font-size-medium"
                title="Personalmente"
                className="blue1"
              >
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Sentirte segura de tus capacidades.{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Emplear toda tu experiencia.{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Comunícarte con seguridad.{" "}
                </BulletItem>
              </Card>
            </Grid>
            <Grid item md={3} sm={6} xs={10}>
              <Card
                avatar={ImageEmocionalmente}
                titleClassName="tenor bold turkis-blue1 center font-size-medium"
                title="Emocionalmente"
                className="blue1"
              >
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Relaciones saludables.{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Lograr mayor balance emocional.{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  Romper la dependencia emocional.
                </BulletItem>
              </Card>
            </Grid>
            <Grid item md={3} sm={6} xs={10}>
              <Card
                avatar={ImageFisicamente}
                titleClassName="tenor bold turkis-blue1 center font-size-medium"
                title="Físicamente"
                className="blue1"
              >
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Verte y sentirte mejor.
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  Mejorar tu condición física.
                </BulletItem>
                <BulletItem icon={bulletIcon3}>Mejorar tu salud.</BulletItem>
              </Card>
            </Grid>
          </Grid>
        </Article>
      </Section>

      <Section color="white">
        <Article>
          <div className="narrow playfair bold center font-size-verylarge blue1 lineheight-verylarge">
            Tú, tienes el poder y la capacidad para lograr todo lo que quieras.
          </div>

          <div className="narrow playfair turkis-blue1 center font-size-large  lineheight-verylarge">
            y <br /> ¡Estoy aqui para ayudarte a conseguirlo!
          </div>
        </Article>
      </Section>

      <Section color="beige1">
        <Article layout="wide">
          <About
            titleClassName="tenor bold blue1 font-size-verylarge  left lineheight-large"
            title="Soy Ivone Herrera"
            image={AboutImage}
          >
            <span className=" blue1 lineheight-large  ">
              <strong></strong>
              En 2012 perdí todo, sufrí <strong> depresión clínica</strong> y
              estuve al borde del <strong> suicidio</strong>. Sobreviví, pero
              durante años seguí sintiendome{" "}
              <strong> insatisfecha con mi vida, </strong>
              <strong> estancada, </strong> con{" "}
              <strong> ansiedad constante </strong> y con muchas{" "}
              <strong> dudas e incertidumbre. </strong>
              <br />
              <br />
              Mi intuición me decía que estaba en mis manos hacer algo radical
              para transformar mi mentalidad, mi situación y generar ingresos.
              <br />
              <br />
              <strong>
                Entonces recordé que años antes, yo era una mujer segura y
                confiaba de tal manera en mí misma, que era capaz de conseguirlo
                todo.
              </strong>{" "}
              No conocía imposibles ni excusas y podía materializar todo lo que
              yo quisiera.
              <strong>
                <br />
                <div className="center">
                  Estaba segura de que, si una fue así, podía volver a serlo.
                </div>
              </strong>
              <br />
            </span>
            <div className="bold center turkis-blue1  font-size-medium lineheight-large">
              ¿Qué elementos hacían la diferencia entre la sensación de poderlo
              todo y la desesperación total?
            </div>

            <div className="bold center blue1  font-size-medium lineheight-large">
              ¿Cuál era el secreto?
            </div>
            <br />
            <span className=" center blue1 lineheight-large ">
              Después de reconstruirme nuevamente en una mujer poderosa y
              confiada de mi misma,{" "}
              <strong>te lo puedo decir con certeza.</strong>
              <br />
              <br />
              <strong>
                {" "}
                Además decidí formarme como coach y mentora para poder enseñarlo
                a más mujeres y para que puedan aplicarlo a su vida.
              </strong>
              <br />
              Y precisamente por eso me estas leyendo ahora.
              <br />
              <br />
              <span className="bold left turkis-blue1 font-size-medium lineheight-large">
                Gracias a todo lo que he vivido y aprendido en este camino,
                estoy preparada y lista para compartirlo contigo.
              </span>
            </span>
          </About>
        </Article>
      </Section>

      <Section color="white">
        <Article className="narrow playfair bold center font-size-verylarge turkis-blue1 lineheight-verylarge">
          Yo sé todo lo que como mujer tienes para ofrecerle al mundo
        </Article>
        <Article className="center font-size-large lineheight-verylarge">
          <br />
          y es mi deber ayudarte a que lo compartas
          <br /> <br />
        </Article>
        <div className="narrow playfair  bold center font-size-large blue1 lineheight-verylarge">
          para que con ello generes los ingresos que te mereces y crees esa vida
          que sueñas.
        </div>
      </Section>

      <Section color="turkis-blue1">
        <Article className="narrow playfair bold white center font-size-verylarge  lineheight-verylarge">
          ¡Por eso existe mi curso Amplifica tu Confianza!
        </Article>
      </Section>

      <Section
        className="playfair font-size-verylarge"
        color="turkis-blue1"
        bgImage={ImageTurkisWay}
        overlay="full"
        overlayColor="rgba(250, 246, 246,.7)"
      >
        <Article layout="wide">
          <Card
            title={
              <span>
                {" "}
                Esto es lo que podrás lograr con mi curso <br /> Amplifica tu
                Confianza{" "}
              </span>
            }
            titleClassName="tenor blue1 bold center font-size-verylarge lineheight-large"
            className="turkis-blue1 lineheight-large"
          >
            <br />
            <br />
            <Grid container spacing={1}>
              <Grid item md={6}>
                <BulletItem icon={bulletIcon2}>
                  Descubrirás lo que quieres lograr en tu vida y por qué.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Descubrirás cómo conectar con la fuente de tu confianza y
                  seguridad personal.{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Te diré como dejar de sabotearte.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Te demostrarás con hechos que eres capaz de lograr todo lo que
                  te propongas.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lograras ver con claridad lo que se interpone entre tú y tus
                  metas.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Te fijarás metas realistas y empezarás a tomar acción para
                  conseguirlas.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Aprenderás a liberarte de las expectativas sociales y
                  familiares.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Dejarán de importarte las opiniones de los demás a cerca de lo
                  que haces o no con tu vida.{" "}
                </BulletItem>
              </Grid>
              <Grid item md={6}>
                <BulletItem icon={bulletIcon2}>
                  Te sentirás segura de ti misma, de tu valor como persona y de
                  todo lo que tienes para ofrecerle al mundo.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Te sentirás motivada y llena de energía y le dirás ¡Adiós las
                  dudas que te paralizaban!
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Aprenderás a tomar decisiones más fácilmente, sin sentir culpa
                  ni remordimientos.{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Desarrollarás estrategias para vencer tus miedos.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Dejar de posponer lo que necesitas hacer.{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Descubrirás el secreto que te ayudará a navegar la
                  incertidumbre que acompaña a la vida.{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Romperás con esas limitaciones imaginarias que te impiden
                  cumplir con tu propósito.{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Aprenderás a confiar firmemente en ti para construir y
                  manifestar la vida que quieres.{" "}
                </BulletItem>
              </Grid>
            </Grid>
          </Card>
        </Article>
      </Section>

      <Section color="white">
        <Article
          layout="wide"
          titleClassName="blue1 tenor font-size-verylarge"
          title="¡Mejor que te lo cuenten las que ya tomaron el curso!"
        >
          <Carousel dots arrows slidesToShow={2} slidesToScroll={1}>
            <Testimonial name={TestName_4} variation="small">
              {" "}
              {Testimonio_4}{" "}
            </Testimonial>
            <Testimonial name={TestName_5} variation="small">
              {" "}
              {Testimonio_5}{" "}
            </Testimonial>
            <Testimonial name={TestName_6} variation="small">
              {" "}
              {Testimonio_6}{" "}
            </Testimonial>
          </Carousel>
        </Article>
      </Section>

      <Section
        color="turkis-blue1"
        bgImage={ImageAreas}
        overlay="full"
        overlayColor="rgba(250, 246, 246, .4)"
      >
        <Article
          title="Amplifica tu Confianza es distinto de otros cursos porque..."
          titleClassName=" white tenor font-size-verylarge lineheight-large "
          layout="narrow"
        >
          <NumericList text={numericListText} />
        </Article>
      </Section>

      <Section
        color="beige"
        //bgImage={ImageOceanBlue}
        // overlay="full"
        // overlayColor="rgba(98, 128, 174,.2)"
      >
        <Article className="center">
          <span className="tenor  bold blue1 center font-size-verylarge  lineheight-extralarge">
            ¿Quieres saber cómo lo haremos?
          </span>
          <br /> <br />
          <span className="center turkis-blue1 font-size-medium">
            {" "}
            Mi método combina{" "}
          </span>
          <span className="bold turkis-blue1 font-size-medium">
            {" "}
            sensibilidad, estrategia y acción.{" "}
          </span>
          <br /> <br />
          <br /> <br />
        </Article>
        <Article>
          <Grid container spacing={3} justify="center">
            <Grid item md={3} sm={6} xs={10}>
              {/*  <Card icon={icon} title="Sensibilidad" className="center">
              Aprende a interpretar tus emociones para que sepas lo que necesitas hacer en cada momento.
              </Card> */}
              <Card
                avatar={ImageSensibilidad}
                title="Sensibilidad"
                titleClassName="bold  blue1 tenor font-size-medium"
                className="center turkis-blue1"
              >
                Aprende a interpretar tus emociones para que sepas lo que
                necesitas hacer en cada momento.
              </Card>
            </Grid>
            <Grid item md={3} sm={6} xs={10}>
              {/* <Card icon={<FaChess size="36px" />}title="Estrategia" className="center">
              Aprende a reajustar tus acciones conscientemente para que te lleven más cerca de tus metas.
              </Card> */}
              <Card
                avatar={ImageEstrategia}
                title="Estrategia"
                titleClassName="bold  blue1 tenor font-size-medium"
                className="center turkis-blue1"
              >
                Aprende a reajustar tus acciones conscientemente para que te
                lleven más cerca de tus metas.
              </Card>
            </Grid>
            <Grid item md={3} sm={6} xs={10}>
              {/* <Card icon={icon} title="Acción" className="center"> */}
              <Card
                avatar={ImageAccion}
                title="Acción"
                titleClassName="bold  blue1 tenor font-size-medium"
                className="center turkis-blue1"
              >
                Toma acción con intención y empieza a ver y disfrutar de
                resultados tangibles rápidamente.
              </Card>
            </Grid>
            <Grid item md={3} sm={6} xs={10}>
              {/* <Card icon={icon} title="Somos un equipo" className="center"> */}
              <Card
                avatar={ImageEquipo}
                title="Somos un equipo"
                titleClassName="bold  blue1 tenor font-size-medium"
                className="center turkis-blue1"
              >
                Soy tu confidente y la voz de apoyo que necesitas para surfear
                los desafíos que se te presentan.
              </Card>
            </Grid>
          </Grid>
        </Article>
      </Section>

      {/* <Section color="beige1">
        <Article
          layout="wide"
          titleClassName=" playfair bold  blue1 font-size-verylarge lineheight-verylarge "
          title="Esto es lo que podrás lograr con mi curso Amplifica tu Confianza"
        >
          <ListCard
            title={TitleConfianza}
            titleClassName=" tenor  blue1 font-size-large"
            text={ListConfianza}
            //image={IvoneImage}
            listItemIcon={bulletIcon3}
          />
        </Article>
      </Section> */}

      <Section color="white">
        <div className="narrow playfair bold center font-size-verylarge turkis-blue1 lineheight-verylarge">
          En pocas palabras vas a enamorarte de ti misma,
          <br /> de todo lo que ya eres y de lo que puedes lograr.
          <br />
          <br />
        </div>
        <div className="center bold font-size-medium lineheight-verylarge">
          Además te sentirás confiada de ti misma y estarás segura de que eres
          <br />
          capaz de lograr TODO lo que te propongas,
          <br />
          sin importar los obstáculos ni las limitaciones que se te presenten.
          <br />
          <br />
        </div>
        <div className="narrow playfair bold center font-size-verylarge blue1 lineheight-verylarge">
          Ese es tu poder personal y ha estado siempre dentro de ti.
        </div>
        <div className="center font-size-medium lineheight-verylarge">
          Yo te ayudo y te guío para que lo actives y lo puedas utilizar en toda
          su potencia.
        </div>
      </Section>

      <Section color="beige1">
        <Article
          layout="wide"
          titleClassName="blue1 tenor font-size-verylarge"
          title="Que ellas te cuenten del curso..."
          className="center"
        >
          <Testimonial name={TestName_10} variation="small" image={IvoneImage}>
            {" "}
            {Testimonio_10}
          </Testimonial>
          <Testimonial name={TestName_7} variation="small" image={IvoneImage}>
            {" "}
            {Testimonio_7}{" "}
          </Testimonial>
        </Article>
      </Section>

      {/* asdjasdjahdjabdj */}
      <Section color="blue">
        <Article
          layout="narrow"
          titleClassName=" tenor  white bold font-size-extralarge lineheight-verylarge"
          title="¿Qué incluye mi curso Amplifica tu Confianza?"
        >
          <ListCard text={List_Incluye} listItemIcon={bulletIcon4}></ListCard>
        </Article>
      </Section>

      <Section color="white">
        <Article
          layout="wide"
          titleClassName=" tenor turkis-blue1 bold font-size-extralarge lineheight-verylarge"
          title="Esto es lo que dicen las que ya han tomado el curso"
        >
          <Carousel dots arrows slidesToShow={1} slidesToScroll={1} fade>
            <Testimonial icon={iconQuotes} name={TestName_3}>
              {" "}
              {Testimonio_3}{" "}
            </Testimonial>
            <Testimonial icon={iconQuotes} name={TestName_2}>
              {" "}
              {Testimonio_2}{" "}
            </Testimonial>
            <Testimonial icon={iconQuotes} name={TestName_9}>
              {" "}
              {Testimonio_9}{" "}
            </Testimonial>
          </Carousel>
        </Article>
      </Section>

      <Section color="white">
        <Article>
          <div className="narrow playfair bold center font-size-verylarge turkis-blue1 lineheight-verylarge">
            Amplifica tu Confianza <br />
            es mucho más que un simple curso digital o coaching grupal.
          </div>
          <br />
          <div className="narrow  center font-size-medium lineheight-verylarge">
            Es un conjunto de herramientas y prácticas que transformaran tu
            mentalidad,
            <br />
            la manera en que te valoras a ti misma y la manera en que actúas en
            tu día a día.
            <br />
            <br />
          </div>
          <div className="narrow playfair bold center font-size-large blue1 lineheight-verylarge">
            Aprenderás una nueva forma de enfrentarte al mundo
            <br /> con seguridad y confianza.
            {/* , sin sentir que eres víctima de las circunstancias nunca más. */}
            <br />
            <br />
          </div>
        </Article>
      </Section>

      <Section color="blue1">
        <Article
          layout="narrow"
          titleClassName=" tenor white bold font-size-extralarge lineheight-verylarge"
          title="Calendario"
        >
          {/* <Grid container spacing={1}> */}
          <Grid item md={12}>
            <Card icon={iconCalendar} className="center">
              {/* <Card icon={icon} title="Title1" className="center"></Card> */}
              <BulletItem icon={bulletIcon4}>
                Las sesiones serán los días Miércoles de 18:00 a 19:30h
                Berlin-España-Francia
              </BulletItem>
              <BulletItem icon={bulletIcon4}>
                Primer Sesión: Miércoles 16 de Junio 2021
              </BulletItem>
              <BulletItem icon={bulletIcon4}>
                Segunda Sesión: Miércoles 23 de Junio 2021
              </BulletItem>
              <BulletItem icon={bulletIcon4}>
                Tercera Sesión: Miércoles 30 de Junio 2021
              </BulletItem>
              <BulletItem icon={bulletIcon4}>
                Cuarta Sesión: Miércoles 7 de Julio 2021
              </BulletItem>
              <BulletItem icon={bulletIcon4}>
                Las sesiones de coaching individuales se agendarán con cada
                participante.
              </BulletItem>
            </Card>
            {/* </Grid> */}
          </Grid>
          {/* <ListCard text={List_Incluye} listItemIcon={bulletIcon4}> */}
        </Article>
      </Section>

      <Section color="white">
        <Article>
          <div className="narrow  turkis-blue1 playfair bold center font-size-verylarge  lineheight-verylarge">
            Amplifica tu Confianza <br /> marcará un antes y un después en tu
            vida.
            <br />
            <br />
          </div>
          <div className="narrow   center font-size-medium lineheight-verylarge">
            Cuando termines el curso estarás capacitada para
            <br /> enfrentarte con placer y emoción a los desafíos que se te
            presenten.
            <br />
            <br />
          </div>
          <div className="narrow playfair bold  center font-size-large blue1 lineheight-verylarge">
            Además podrás recurrir a estas poderosas herramientas de por vida y
            <br />
            cada vez te será más fácil materializar el estilo de vida que
            anhelas.
          </div>
          <br />
          <br />
        </Article>
      </Section>

      <Section color="white">
        <Article>
          <div className="narrow playfair bold center font-size-verylarge turkis-blue1 lineheight-verylarge">
            Lo aprendido en este curso <br /> se convertirá en tu nuevo estilo
            de vida.
            <br />
            <br />
          </div>
          <div className="narrow playfair bold center font-size-verylarge blue1 lineheight-verylarge">
            ¡Te va a encantar!
          </div>
        </Article>
      </Section>

      <Section color="blue1">
        <Article
          layout="wide"
          className="white playfair bold center font-size-verylarge lineheight-verylarge "
        >
          ¿Te imaginas ser capaz conseguir todo lo que quieres y que <br /> a
          veces no te atreves ni a contar?
          <br />
          ¿Te imaginas que la inversión sea sólo de...?
          <br />
          <br />
          <Grid container spacing={3} justify="center">
            <Grid item md={4}>
              <Card
                icon={iconEuro}
                className="center tenor font-size-large  lineheight-verylarge"
              >
                1 solo pago de
                <br />
                <div className="center playfair font-size-large  lineheight-verylarge">
                  <div
                    className={basePrice !== Number(price) ? "line-through" : "price"}
                  >
                    {basePrice} €
                  </div>
                  {basePrice !== Number(price) && <div className="price">{price} €</div>}
                </div>
              </Card>
            </Grid>
          </Grid>
          <br />
          <div
            id="cta"
            className="tenor no-bold white center font-size-large lineheight-large"
          >
            ¿Estas lista para experimentar esta poderosa transformación?
          </div>
          <CtaButton
            label="¡Sí, Quiero el curso!"
            onClick={() => setShowCheckout(true)}
          />
        </Article>
      </Section>
      {showCheckout && (
        <Section color="white" small>
          <Article layout="narrow" className="center">
            <Checkout
              amount={price}
              product="Curso Amplifica tu Confianza"
              onChange={setDiscount}
            />
          </Article>
        </Section>
      )}
      <Section color="beige1" id="ES-PARA-TI">
        <Article layout="wide">
          <div className="center playfair bold blue1 font-size-verylarge lineheight-verylarge ">
            ¿Cómo saber si este curso es para ti?
            <br />
            <br />
          </div>
          <Grid container spacing={1}>
            <Grid item md={6}>
              <ListCard
                titleClassName="tenor bold turkis-blue1 font-size-large "
                title={Title_SI}
                text={List_SI}
                listItemIcon={bulletIconSI}
              />
            </Grid>
            <Grid item md={6}>
              <ListCard
                titleClassName="tenor bold turkis-blue1 font-size-large "
                title={Title_NO}
                text={List_NO}
                listItemIcon={bulletIconNO}
              />
            </Grid>
          </Grid>
        </Article>
      </Section>

      {/* <Section color="blue" id="Lista-de-espera">
        <Article layout="wide"></Article>
      </Section> */}

      <Section color="turkis-blue1">
        <Article
          layout="wide"
          titleClassName="white tenor font-size-verylarge"
          title="Esto es lo que dicen los que ya han trabajado conmigo"
        >
          <Carousel dots arrows slidesToShow={2} slidesToScroll={1}>
            <Testimonial name={TestName_1} variation="small">
              {" "}
              {Testimonio_1}{" "}
            </Testimonial>
            <Testimonial name={TestName_8} variation="small">
              {" "}
              {Testimonio_8}{" "}
            </Testimonial>
            {/* <Testimonial name={TestName_6} variation="small">
              {" "}
              {Testimonio_6}{" "}
            </Testimonial> */}
          </Carousel>
        </Article>
      </Section>

      <Section color="white">
        <Article
          layout="narrow"
          titleClassName="center tenor blue1 font-size-large lineheight-verylarge "
          title="Preguntas Frecuentes"
        >
          <Accordion>
            <AccordionSummary className="bold " expandIcon={iconPreguntas}>
              ¿Qué métodos de pago existen?
            </AccordionSummary>
            <AccordionDetails>
              Puedes pagar mediante Paypal, transferencia bancaria y tarjeta de
              crédito o débito.
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary className="bold" expandIcon={iconPreguntas}>
              ¿Por dónde se dará el curso?
            </AccordionSummary>
            <AccordionDetails>
              El curso se realizará virtualmente en la plataforma de zoom. Para
              ti no tiene ningún costo adicional. Únicamente necesitas descargar
              la aplicacion gratuita de zoom, ya sea en tu teléfono movil o en
              tu computadora personal, y abrir una cuenta con tu correo
              electrónico.
              <br />
              El enlace para unirte a las sesiones de zoom te lo proporcionaré
              vía email una vez que estes registrada y hayas realizado el pago.
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary className="bold" expandIcon={iconPreguntas}>
              ¿Cómo me uno al grupo de Telegram?
            </AccordionSummary>
            <AccordionDetails>
              El enlace para unirte al grupo de Telegram te lo proporcionaré vía
              email una vez que estes registrada y hayas realizado el pago.
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary className="bold" expandIcon={iconPreguntas}>
              ¿Qué pasa si no tengo Telegram?
            </AccordionSummary>
            <AccordionDetails>
              Telegram es gratuito. Es necesario que instales la aplicación en
              tu teléfono mobil para poder acceder al material adicional y
              obtener los beneficios que se compartiran en el grupo.
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary className="bold" expandIcon={iconPreguntas}>
              ¿Cuánto tiempo tendré que dedicarle?
            </AccordionSummary>
            <AccordionDetails>
              Necesitarás una dedicación mínima de tres horas a la semana, de
              las cuales: 90 min serán para la sesión grupal en directo y 90 min
              adicionales para trabajar el workbook con los ejercicios de la
              semana.
              <br />
              Los ejercicios los puedes realizar en pequeños bloques de 15 a 20
              min en diversos momentos durante de la semana.
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary className="bold" expandIcon={iconPreguntas}>
              ¿Qué pasa si no tengo tiempo para hacer los ejercicios del
              workbook?
            </AccordionSummary>
            <AccordionDetails>
              Los ejercicios del Workbook son la base sobre la que trabajaremos
              durante las sesiones en directo. La idea es que vayas
              trabajandolos según avanza el curso.
              <br />
              <br />
              El realizar los ejercicios a tiempo, te permitirá sacarle el mayor
              provecho al curso y también plantear tus dudas y dificultades
              durante la siguiente sesión grupal.
              <br />
              <br />
              Yo sé que habrá momentos en que te sientas bloqueada, no te
              preocupes, para eso existe el grupo de Telegram, para brindarte el
              acompañamiento que necesitas para enfrentar tus desafíos y
              mantenerte enfocada y avanzando. ¡Utilizalo!
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary className="bold" expandIcon={iconPreguntas}>
              ¿Qué pasa si no puedo asistir a las sesiones grupales?
            </AccordionSummary>
            <AccordionDetails>
              Las sesiones grupales son muy importantes porque allí presento el
              contenido, resuelvo las dudas que tengas y te doy consejos de como
              superar tus retos personales.
              <br />
              <br />
              Lo más recomendable es que aproveches esta oportunidad y asistas a
              las 4 sesiones.
              <br />
              <br />
              Las sesiones en directo se grabarán y la grabación estará
              disponible por 24h para que la puedas ver. Después será eliminada.
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary className="bold" expandIcon={iconPreguntas}>
              ¿Qué pasa si tengo dudas durante la semana?
            </AccordionSummary>
            <AccordionDetails>
              Si tienes dudas durante el curso solo tienes que plantearlas en el
              grupo de Telegram o en la siguiente sesión de coaching en directo.
              <br />
              <br />
              El grupo de Telegram te servirá, además, para compartir tus
              experiencias, dificultades, bloqueos y logros durante el camino.
              ¡Sácale provecho!
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary className="bold" expandIcon={iconPreguntas}>
              ¿En cuánto tiempo empezaré a ver resultados?
            </AccordionSummary>
            <AccordionDetails>
              Esto depende de qué tan comprometida estés para aplicar las
              estrategias y las herramientas desde el primer momento.
              <br />
              <br />
              Es fundamental que traigas una mente abierta para integrar nuevas
              ideas y perspectivas y las pongas en práctica.
              <br />
              <br />
              Si en cada oportunidad que tengas, pones en práctica las
              estrategias y las herramientas aprendidas durante en curso, y
              además realizas los ejercicios del workbook, rápidamente sentirás
              la diferencia.
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary className="bold" expandIcon={iconPreguntas}>
              ¿Qué pasa al terminar el curso quiero continuar con sesiones
              individuales?
            </AccordionSummary>
            <AccordionDetails>
              Estaré más que encantada de seguir acompañándote en tu camino y
              ayudarte a superar tus desafíos. Y, como ya hemos trabajado
              juntas, el avance será más sencillo.
              <br />
              <br />
              Además te daré un precio preferente para las sesiones que
              necesites.
            </AccordionDetails>
          </Accordion>
        </Article>
      </Section>
      <Footer />
    </>
  )
}
