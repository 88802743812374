import { Input } from "@material-ui/core"
import React from "react"
import "./checkout.css"

declare global {
  var paypal: any
}

interface Props {
  amount: string
  product: string
  onChange?: (discount: number) => {}
}
const Checkout: React.FC<Props> = ({ amount, product, onChange }) => {
  const [purchased, setPurchased] = React.useState(false)
  const paypalContainerRef = React.useRef()
  React.useLayoutEffect(() => {
    if (paypalContainerRef && paypalContainerRef.current) {
      paypalContainerRef.current.innerHTML = ""
    }
    // document.querySelector('#paypal-button-container').innerHTML = '';
    paypal
      .Buttons({
        onShippingChange: function (data, actions) {
          return actions.resolve()
        },
        createOrder: function (data, actions) {
          // This function sets up the details of the transaction, including the amount and line item details.
          //   console.log("creating order", data)
          return actions.order.create({
            intent: "CAPTURE",

            purchase_units: [
              {
                currency_code: "EUR",
                source_currency: "EUR",
                description: product,
                amount: {
                  description: product,
                  value: amount,
                },
              },
            ],
            application_context: {
              shipping_preference: "NO_SHIPPING",
            },
          })
        },
        onApprove: function (data, actions) {
          // This function captures the funds from the transaction.
          return actions.order.capture().then(function (details) {
            // console.log(details)
            // // This function shows a transaction success message to your buyer.
            // console.log(
            //   "Transaction completed by ",
            //   details.payer.name.given_name
            // )
            // console.log("Transaction data ", data)
            setPurchased(true)
          })
        },
      })
      .render("#paypal-button-container")
  }, [amount])

  if (purchased) {
    return (
      <div className="checkout-container">Compra exitosa. Ya estás dentro</div>
    )
  }
  return (
    <div className="checkout-container">
      <div>
        <div className="raleway blue1 font-size-small lineheight-normal">
          Si tienes un código de descuento introdúcelo aquí
        </div>
        <Input
          placeholder="Código de descuento"
          color="primary"
          onChange={e => {
            const v = e.currentTarget.value
            const today = new Date()
            if (v.toUpperCase() === "CONFIOENMI") {
              // if before end of tuesday 18.05 0.4
              // setDiscount(0.4)
              // if after tuesday and before 20.05 0.5
              // setDiscount(0.5);
              onChange(0.4)
            } else {
              onChange(1)
            }
          }}
        />
      </div>
      <div
        className="checkout-paypal"
        id="paypal-button-container"
        ref={paypalContainerRef}
      />
    </div>
  )
}

export default Checkout
